import {type ClassValue, clsx} from 'clsx'
import {twMerge} from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

const IS_SERVER = typeof window === 'undefined'
export default function getURL(path: string) {
  const baseURL = IS_SERVER
    ? process.env.NEXT_PUBLIC_SITE_URL!
    : window.location.origin
  return new URL(path, baseURL).toString()
}

export function isExpiringSoon(dateObj: Date | null | undefined): boolean {
  if (!dateObj) return false

  const oneMonthFromNow = new Date()
  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1)

  return new Date(dateObj) < oneMonthFromNow
}
