import Image from 'next/image'
import * as React from 'react'
import {Button} from '../ui/button'
import Link from 'next/link'
import {ExternalLink} from 'lucide-react'

const GeneralError = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#F4F4F5] gap-4 pb-12">
      <Image
        src="/images/error_illustration.png"
        alt="General Error"
        className="w-40"
        width={993}
        height={716}
        priority
      />
      <p className="mt-5 text-xl font-semibold">Whoa ...</p>
      <p className="text-xl font-semibold">Something went wrong.</p>
      <p>
        We’re working hard to fix this. Press the button below to go back to the
        info.link dashboard.
      </p>
      <Button>
        <Link href="/dashboard" className="flex flex-row items-center gap-2">
          <ExternalLink />
          Go to Dashboard
        </Link>
      </Button>
    </div>
  )
}

export default GeneralError
