'use client' // Error components must be Client Components
import * as Sentry from '@sentry/nextjs'
import GeneralError from '@/components/ErrorMessages/GeneralError'
import {useEffect} from 'react'

export default function Error({
  error,
  reset
}: {
  error: Error & {digest?: string}
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    Sentry.captureException(error)
  }, [error])
  return (
    <html>
      <body>
        <GeneralError />
      </body>
    </html>
  )
}
